import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import { CardsThree } from "../homepage";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import TopFormWithImage from "../../components/TopFormCenterImage";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { useMedia } from "use-media";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const TopImage = require("../../assets/img/feature/conversation_ai/con_ai_header.png");
const TopImage_mob = require("../../assets/img/feature/conversation_ai/con_ai_header.png");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

export const cardOne = require("../../assets/images/homepage/group1.png");
export const cardTwo = require("../../assets/images/homepage/group4.png");

const section1 = require("../../assets/img/feature/conversation_ai/con_ai_sec_1.png");
const section2 = require("../../assets/img/feature/conversation_ai/con_ai_sec_2.png");
const section3 = require("../../assets/img/feature/conversation_ai/con_ai_sec_3.png");
const section4 = require("../../assets/img/feature/conversation_ai/con_ai_sec_4.png");
const section5 = require("../../assets/img/feature/conversation_ai/con_ai_sec_5.png");
const section6 = require("../../assets/img/feature/conversation_ai/con_ai_sec_6.png");
const section7 = require("../../assets/img/feature/conversation_ai/con_ai_sec_7.png");
const section8 = require("../../assets/img/feature/conversation_ai/con_ai_sec_8.png");
const section9 = require("../../assets/img/feature/conversation_ai/con_ai_sec_9.png");

export const autoDesigner = require("../../assets/img/feature/updated/automation.png");
export const chatChannel = require("../../assets/img/feature/updated/chat-channel.png");
export const logs = require("../../assets/img/feature/updated/logs.png");
export const marketplace = require("../../assets/img/feature/updated/marketplace.png");
export const dialog = require("../../assets/img/feature/updated/dialog.png");
export const onprem_icon = require("../../assets/img/feature/updated/on_prem.png");
export const faqBuilder = require("../../assets/img/feature/agent_handover/all_feature_faq_icon.png");
export const liveAgent = require("../../assets/img/feature/agent_handover/all_feature_agent_handover_icon.png");
export const chabotApproval = require("../../assets/img/feature/agent_handover/all_feature_approvals_icon.png");
const knowledgeAi = require("../../assets/img/feature/knowledge_ai/knowledgeai_icon.svg");

export const c_ai = require("../../assets/img/feature/updated/c_ai.png");

export const cardData = [
  {
    image: cardOne,
    header: "Workativ for Conversational IT Support",
    content: `Deliver faster and superior IT Support, powered by Conversational AI and Automation. Augment your IT Help Desk to scale your support easily in a few clicks. `,
    content_1: `No coding required.`,
    linkAddress: "/assistant/it-helpdesk-chatbot",
    backgroundClass: "bg_card_1",
  },
  {
    image: cardTwo,
    header: "Workativ for Conversational HR Support",
    content: `Transform HR experience for employees with our AI-powered chatbots with prebuilt HR process automation. Free up your HR staff to focus on high value work.`,
    content_1: ` No coding required.`,
    linkAddress: "/conversational-ai-platform/conversational-hr-support",
    backgroundClass: "bg_card_2",
  },
];

export const otherFeatures = [
  {
    image: dialog,
    alt: "Chatbot Builder​",
    header: "Chatbot Builder​",
    content:
      "Use no-code visual canvas to create engaging conversations for your chatbot easily in minutes.",
    link: "/conversational-ai-platform/chatbot-builder",
  },
  {
    image: faqBuilder,
    alt: "FAQ Bot Builder",
    header: "FAQ Bot Builder",
    content:
      "Simple and easy to understand UI to help you automate your repetitive FAQs easily in minutes.",
    link: "/conversational-ai-platform/faq-chatbot-builder",
  },
  {
    image: autoDesigner,
    alt: "Chatbot Automation​",
    header: "Chatbot Automation​",
    content:
      "Add IT or HR workflow automations to chatbot through app integration and pre-built app workflows in few clicks.",
    link: "/conversational-ai-platform/chatbot-automation",
  },
  {
    image: knowledgeAi,
    alt: "Knowledge AI",
    header: "Knowledge AI​",
    content:
      "Knowledge AI leverages the power of LLMs and Generative AI to provide contextually relevant responses to user queries.",
    link: "/conversational-ai-platform/knowledge-ai",
    new: true,
  },
  {
    image: chabotApproval,
    alt: "Chatbot Approvals",
    header: "Chatbot Approvals​",
    content:
      "Approving chatbot tasks made easy with our approval management. Set approval reminders, expiry, tracking, and more.",
    link: "/conversational-ai-platform/approval-management-chatbot",
  },
  {
    image: marketplace,
    alt: "Chatbot Workflow Marketplace",
    header: "Chatbot Marketplace",
    content:
      "Download pre-built chatbots, 100+ app integrations, and 1000+ app workflows in seconds and go live instantly.",
    link: "/conversational-ai-platform/app-workflow-template-marketplace",
  },
  {
    image: chatChannel,
    alt: "Omnichannel",
    header: "Omnichannel",
    content:
      "Deploy chatbot on your favorite collaboration apps like Teams, Slack, or as Chat Widget easily in few clicks.",
    link: "/conversational-ai-platform/chatbot-channels",
  },

  {
    image: liveAgent,
    alt: "Live Agent Handover",
    header: "Live Agent Handover",
    content:
      "Seamless ‘agent handover’ of chatbot to live agents with complete user context and conversation history.",
    link: "/conversational-ai-platform/agent-handover-chatbot",
  },
  {
    image: logs,
    alt: "Chatbot Analytics & Performance",
    header: "Chatbot Analytics & Performance",
    content:
      "Gather insight to craft better end user experiences and deliver better chatbot support to your employees.",
    link: "/conversational-ai-platform/chatbot-analytics-performance",
  },
];

export default function DialogDesigner() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="#1 leading Conversational AI Platform - Free Signup, Free to Use | Workativ Assistant Chatbot"
        description="Explore workativ assistants' conversational ai platform with advanced NLP and context management etc."
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogImage={TopImage}
        ogTitle="#1 leading Conversational AI Platform - Free Signup, Free to Use | Workativ Assistant Chatbot"
        ogDescription="Explore workativ assistants' conversational ai platform with advanced NLP and context management etc."
      />
      <Container
        additionalClass={"feature-display-none"}
        additionalClassParent={"indvidual_features_parent_cont"}
      >
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <TopFormWithImage
            image={TopImage}
            altImage={"Conversation AI Platform"}
            image_mob={TopImage_mob}
            additionalClassImage={"width-100"}
            additionalClassSection={"features_indvidual_dec"}
          >
            <TopFormWithImage.Header>
              Conversation AI Platform
            </TopFormWithImage.Header>
            <TopFormWithImage.Content>
              Engaging conversations happen when you combine good dialog
              management, powerful NLP with LLM, and contextual intelligence.
              Our hybrid bot platform is purpose built to deliver intelligent,
              personalized and scalable chatbot for support in minutes.
            </TopFormWithImage.Content>
            <RequestForm isFooterForm={false} />
          </TopFormWithImage>
          {/* {isSmall ? null : <OnScrollPopup />} */}
          <section className="features_content_left features_img_left pl-10-tage landing_page trial_page_img bg_trial_page ">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <h3 className="font-section-header">
                    <span>Chatbot Builder and Dialog Management</span>
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                    Creating and managing chatbot dialogs for workplace support
                    can be challenging, but our Workativ Assistant has the
                    solution. Our chatbot builder, powered by advanced NLU and
                    LLM, offers a no-code interface for easy dialog creation.
                    It’s equipped with multiple functions, making it a breeze to
                    build simple to complex conversations in minutes.
                  </p>

                  <div class="card_link_landing ">
                    <a
                      className="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                      href="/conversational-ai-platform/chatbot-builder"
                    >
                      More on chatbot builder&nbsp; &#8594;
                    </a>
                  </div>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <img
                    loading="lazy"
                    className
                    src={section1}
                    alt="Chatbot Builder and Dialog Management"
                  />
                </div>
              </div>
            </div>
          </section>

          {/*  */}
          <div className="features_container_left">
            <section className="features_img_left pl-10-tage landing_page trial_page_img ">
              <div className="container">
                <div className="row">
                  <div className="center_feature_wrapper margin-bottom-0">
                    <h2 className="font-section-header">The power of Hybrid</h2>
                    <p className="font-section-normal-text-testimonials line-height-18  cc_para_1">
                      Our conversational ai platform is powered by an advanced
                      Natural Language Processing (NLP) engine and LLM. So,
                      whilst the the chatbot builder and dialog management helps
                      create and manage conversations, the NLP engine delivers
                      the AI for chatbot.
                    </p>
                    <p className="font-section-normal-text-testimonials line-height-18 cc_para_3">
                      Our NLP provides advanced intent detection and entity
                      extraction from users’ conversations or utterances to
                      process simple or complex conversations and execute tasks
                      or workflows depending on user’s request in seconds.
                    </p>
                  </div>

                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <img
                        loading="lazy"
                        src={section2}
                        alt="intent recognition"
                      />
                    </picture>
                  </div>
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-sub-header">
                      Intent Recognition
                    </h3>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Intents are goals, queries, or utterances that the user
                      types into the chatbot for a response. Through our
                      powerful Natural Language Understanding (NLU) engine,
                      chatbot built on our platform can understand the intent
                      behind a question or request and deliver an appropriate
                      response or execute an action.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="features_img_left pl-10-tage landing_page trial_page_img ">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-sub-header">
                      Multiple-Intents Detection
                    </h3>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      User interactions with bot are often complex and can
                      involve multiple requests in a single query. Our advanced
                      NLP engine can detect multiple intents in a single user
                      query and help resolve them appropriately without any
                      confusion.
                    </p>
                  </div>
                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <img
                        loading="lazy"
                        src={section3}
                        className={`${isSmall ? "" : "width-90"}`}
                        alt="Multiple-Intents Detection"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
            <section className="features_img_left pl-10-tage landing_page trial_page_img ">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <img
                        loading="lazy"
                        src={section4}
                        alt="Entity Extraction"
                      />
                    </picture>
                  </div>
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-sub-header">
                      Entity Extraction
                    </h3>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Our NLP provides powerful entity extraction to process a
                      specific request. Example of entities are “email”, “time”,
                      “date” etc. Entities are required to process a request
                      based on the user intent. E.g. I want to book a meeting on
                      Monday. Here “book a meeting” is the intent, and “Monday”
                      is the entity.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="features_img_left pl-10-tage landing_page trial_page_img ">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-sub-header">
                      Auto-correction, Spelling, and Grammar
                    </h3>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Users are always multi-tasking and often are using mobile
                      devices to interact with chatbot from messaging apps such
                      as MS Teams, or Slack. And, the common issue is - typo.
                      But thanks to our advanced NLP - can pick up typo or
                      spelling mistakes, auto-corrections, and as well poor
                      grammar, so the chatbot is able to process the requests as
                      intended.
                    </p>
                  </div>
                  <div className="col-md-6 feature_page_img_right ">
                    <picture>
                      <img
                        loading="lazy"
                        src={section5}
                        className={`${isSmall ? "" : "width-90"}`}
                        alt="Auto-correction, Spelling, and Grammar"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="bg_conversation_ai">
            <section className=" features_img_left pl-10-tage landing_page trial_page_img bg_trial_page ">
              <div className="container">
                <div className="row">
                  <div className="center_feature_wrapper margin-bottom-0">
                    <h2 className="font-section-header">Context Management</h2>
                    <p className="font-section-normal-text-testimonials line-height-18 cc_para_3">
                      Intelligence comes from data, and smartness come from
                      context. Our conversational ai platform provides advanced
                      context management capabilities for the chatbot. Context
                      can be user data, session data, workflow data, and more.
                      Being able to manage and use variety of context data at
                      several levels in conversations and workflow execution
                      helps you cover simple to advanced usecase easily.
                    </p>
                  </div>
                  <div className="col-md-6 feature_page_img_right ">
                    <img
                      loading="lazy"
                      src={section6}
                      className={`${isSmall ? "" : "width-90"}`}
                      alt="context management & context switching"
                    />
                  </div>
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-sub-header">
                      Context Switching
                    </h3>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      User conversations are never singular. A typical
                      conversation may involve more than one context and goal,
                      and even more confusing when the user decides to change
                      the request halfway. Workativ handles context switching
                      swiftly without loosing users.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className=" features_img_left pl-10-tage landing_page trial_page_img bg_trial_page ">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 feature_page_content_left">
                    <h3 className="font-section-sub-header">
                      Context Variables
                    </h3>
                    <p className="font-section-normal-text-testimonials line-height-18">
                      Context variables are session data, user data, and
                      workflow data. Workativ Assistant provides context
                      variable-data that you can use at several places e.g.,
                      greeting a user, retrieve user information using workflow
                      calls to applications, and data to execute workflows etc.
                      With workativ, you can customize how you use context
                      variables to personalize your chatbot.
                    </p>
                  </div>
                  <div className="col-md-6 feature_page_img_right ">
                    <img
                      loading="lazy"
                      className
                      src={section7}
                      alt="context variables"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="features_img_left pl-10-tage landing_page trial_page_img ">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_img_right ">
                  <img
                    loading="lazy"
                    src={section8}
                    alt="manage disambiguation"
                    className={`${isSmall ? "" : "width-90"}`}
                  />
                </div>
                <div className="col-md-6 feature_page_content_left">
                  <h3 className="font-section-sub-header">Manage Ambiguity</h3>
                  <p className="font-section-normal-text-testimonials line-height-18 ">
                    User conversations are never simple and can easily cause
                    ambiguity for the chatbot. A true conversational ai platform
                    must disambiguate.
                  </p>
                  <p className="font-section-normal-text-testimonials line-height-18 ">
                    Using the dialog management tools in workativ, you can use
                    this feature so chatbot can “clarify” user request or intent
                    if the request is unclear or confusing.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="features_img_left pl-10-tage landing_page trial_page_img ">
            <div className="container">
              <div className="row">
                <div className="col-md-6 feature_page_content_left">
                  <h3 className="font-section-sub-header">
                    Digression - More than switching topics
                  </h3>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    We all switch topics in our conversations and often times
                    return back to our original topic. Imagine such
                    conversations with bot.
                  </p>
                  <p className="font-section-normal-text-testimonials line-height-18">
                    {" "}
                    Our NLP can not only handle user switching queries in the
                    middle of a request but also can get back to the original
                    query without user having to start all over again.
                  </p>
                </div>
                <div className="col-md-6 feature_page_img_right ">
                  <picture>
                    <img
                      loading="lazy"
                      src={section9}
                      alt="Digression"
                      className={`${isSmall ? "" : "width-90"}`}
                    />
                  </picture>
                </div>
              </div>
            </div>
          </section>
          <section className="cards_features">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-12 col-sm-12 cards_features_header">
                  <div className="build_needs">
                    <div className="col-lg-12 col-md-12 col-12 p-0 center_features_header">
                      <h2 className="font-section-header">
                        Explore More Features
                      </h2>
                      <p className="font-section-normal-text-testimonials line-height-18">
                        Our 3-in-1 support automation platform combines the
                        power of Gen AI chatbot for automating chats, App
                        workflow automation for auto-resolution of issues, and
                        Shared Live Inbox for seamless live chat interactions.
                        And more…!
                      </p>
                    </div>
                  </div>
                  <section className="whitepaper_cards">
                    <div className="container-fluid p-0 c_a">
                      <div className="row">
                        <ul className="cards">
                          {otherFeatures.map((data) => (
                            <li className="cards__item">
                              <div className="card">
                                <div className="card__image">
                                  <img
                                    loading="lazy"
                                    src={data.image}
                                    alt={data.alt}
                                  ></img>
                                </div>
                                <div className="card__content">
                                  <div className="card__title font-section-normal-text-medium">
                                    {data.header}
                                    {data.beta ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        Beta
                                      </span>
                                    ) : null}
                                    {data.new ? (
                                      <span className="all-features-new-launch font-section-small-signup-form">
                                        New
                                      </span>
                                    ) : null}
                                  </div>
                                  <p className="card__text font-section-small-text pb-0">
                                    {data.content}
                                  </p>
                                  <div class="card_link_landing">
                                    <a
                                      class="font-section-text-link text-decoration-underline text-underline-offset color-anchor-blue"
                                      href={data.link}
                                    >
                                      Know more&nbsp; &#8594;
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
          {/* <GoToPopup />
          <CookiesPoup /> */}
          <div className="isfooter_form">
            <RequestForm isFooterForm={true} />
          </div>
        </Layout>
      </Container>
    </>
  );
}
